import { useParams } from 'react-router'
import FilesDropZone, { FilesDropZoneRef, UploadTaskStatus } from '@/routes/dashboard/files/FilesDropZone'
import { useState, forwardRef, useImperativeHandle, useRef, useEffect } from 'react'
import { StorageReference, getStorage } from 'firebase/storage'
import { ref as firebaseRef } from 'firebase/storage'
import { kGcsUserFileUploadsBucketRef } from '@/constants/constants-gcs'
import { getAuth } from 'firebase/auth'
import { useAppDispatch } from '@/store/store-hooks'
import { chatV2CurrentSourceAssistantToggleSelectedFile, ChatV2Feature } from '../store/chat-v2.slice'
import { getFileMetadataFromGCS, fileUploadIsProcessing } from '@/routes/dashboard/files/files-utils'
import { FileUploadStatusDialog } from '@/routes/dashboard/files/FileUploadStatusDialog'
import { getStorageFolderNameByFeature } from './chat-window-dropzone-utils'

type ChatWindowDropZoneMultifileProps = {
  hideDropZoneUI?: boolean
}

export interface ChatWindowDropZoneMultifileRef {
  openSystemFileSelector: () => void
}

const ChatWindowDropZoneMultifile = forwardRef<ChatWindowDropZoneMultifileRef, ChatWindowDropZoneMultifileProps>(function ChatWindowDropZoneMultifile(
  props,
  ref
) {
  const { hideDropZoneUI = false } = props

  // Get the current user
  const auth = getAuth()
  const uid = auth.currentUser?.uid

  // Get the chatId and chatFeature from URL params
  const { chatFeature, chatId } = useParams()

  const dispatch = useAppDispatch()

  // Derive the currentFolder for uploads
  const storage = getStorage(undefined, kGcsUserFileUploadsBucketRef)
  const uploadFolderPath = `users/${uid}/${getStorageFolderNameByFeature(chatFeature as ChatV2Feature)}`
  const currentFolderRef: StorageReference | null = firebaseRef(storage, uploadFolderPath)

  // Local state
  const [uploadTasks, setUploadTasks] = useState<Map<string, UploadTaskStatus>>(new Map())
  const [statusDialogOpen, setStatusDialogOpen] = useState<boolean>(false)

  // Use a ref for processedFiles to ensure the latest value is available in recursive calls
  const processedFilesRef = useRef<Set<string>>(new Set())

  // Expose method for opening the computer file selector from FilesDropZone
  const filesDropZoneRef = useRef<FilesDropZoneRef | null>(null)

  useImperativeHandle(ref, () => ({
    openSystemFileSelector: () => {
      filesDropZoneRef.current?.openSystemFileSelector()
    },
  }))

  const handleTaskListItemUpdated = (task: UploadTaskStatus) => {
    setUploadTasks((prev) => new Map(prev).set(task.uploadableFile.id, task))
    setStatusDialogOpen(true)
  }

  const handleCloseDialog = () => {
    setStatusDialogOpen(false)
    setUploadTasks(new Map())
  }

  const setSelectedFileForChat = (chatId: string, fileFullPath: string) => {
    console.log('Setting global state selected file for chat: ', chatId, fileFullPath)
    dispatch(
      chatV2CurrentSourceAssistantToggleSelectedFile({
        conversationId: chatId,
        filePath: fileFullPath,
      })
    )
  }

  // Monitor for completed upload tasks and start checking the files' processing status
  useEffect(() => {
    uploadTasks.forEach((task, key) => {
      if (task.complete && !processedFilesRef.current.has(key)) {
        const fileFullPath = `${uploadFolderPath}/${task.uploadableFile.fileName}`
        pollFileProcessingStatus(fileFullPath, key)
      }
    })
  }, [uploadTasks, uploadFolderPath, chatId])

  /**
   * Poll the file's processing status in Google Cloud Storage (GCS).
   * This function is currently only logging the file's processing completion, as the
   * component for displaying the file processing status is the files display component,
   * which has not yet been implemented.
   */
  const pollFileProcessingStatus = async (fileFullPath: string, taskId: string) => {
    if (processedFilesRef.current.has(taskId)) {
      return
    }
    try {
      // Check the status of this file's processing in GCS
      const fileMetadata = await getFileMetadataFromGCS(fileFullPath)
      const isProcessing = fileUploadIsProcessing(fileMetadata)

      if (isProcessing) {
        // If still processing, poll again
        setTimeout(() => pollFileProcessingStatus(fileFullPath, taskId), 2000)
      } else {
        // File processing is complete
        if (!processedFilesRef.current.has(taskId)) {
          console.log('File processing complete for: ', fileFullPath)
          if (chatId) {
            setSelectedFileForChat(chatId, fileFullPath)
          }
          processedFilesRef.current.add(taskId)
        }
      }
    } catch (error) {
      console.error('Error polling file processing status:', error)
    }
  }

  return (
    <div className="px-4">
      <FilesDropZone
        ref={filesDropZoneRef}
        onTaskListItemUpdated={handleTaskListItemUpdated}
        currentFolder={currentFolderRef}
        singleFileLimit={false}
        hideUploadProgressOverlay={true}
        hideDropZoneUI={hideDropZoneUI}
      />
      <FileUploadStatusDialog open={statusDialogOpen} tasks={uploadTasks} onClose={handleCloseDialog} />
    </div>
  )
})

export default ChatWindowDropZoneMultifile
