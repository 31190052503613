import { ChatV2MessageReferenceType } from '../store/chat-v2.slice'

export default function referenceMetadataToTitle(reference: ChatV2MessageReferenceType, isBBCitation = false): string {
  // Format the source title based on the metadata
  let title = ''

  // DOCUMENT QUERY TITLE: Construct the title from storageRefFullPath
  if (typeof reference.metadata?.storage_ref_full_path === 'string') {
    // extract the file name from the full path
    const fileName = reference.metadata.storage_ref_full_path.split('/').pop()
    title += fileName ?? ''
  }

  // LRR TITLE: Construct the title name from the file_path by splitting the path
  else if (typeof reference.metadata?.file_path === 'string') {
    // Add the LRR citation if present
    if (typeof reference.metadata?.citation === 'string' && reference.metadata?.citation != '') {
      title += `${reference.metadata.citation}\n`
    }

    // Add the currency if present
    if (typeof reference.metadata?.currency === 'string' && reference.metadata?.currency != '') {
      title += ` ${reference.metadata.currency}\n`
    }

    const segments = reference.metadata.file_path.toUpperCase().replace(/-/g, ' ').split('/').join('\n')
    title += `${segments}`
  }

  // LRR V2: TITLE, CITATION
  else if (reference.metadata?.reference_type === 'lrr_v2') {
    title += `${reference.metadata.title}`

    // Add the LRR citation if present
    if (typeof reference.metadata?.citation === 'string' && reference.metadata?.citation != '') {
      title += `, ${reference.metadata.citation}`
    }
  }

  // CASELAW V1 TITLE
  else if (typeof reference.metadata?.case_id === 'string') {
    const publicationInfo = reference.metadata.publication_info

    title += `${reference.metadata.title}\n`
    title += publicationInfo
  }

  // CASELAW V2 TITLE
  else if (typeof reference.metadata?.parent_id === 'string') {
    // When isBBCitation, a suffix is generated and the title is italicized
    const caseTitle = reference.metadata.title
    title += `${caseTitle}`

    if (!isBBCitation) {
      const jurisdiction_code = reference.metadata.jurisdiction_code
      const year = reference.metadata.decide_date.split('-')[0]
      const details = `${year}, ${jurisdiction_code}, ${reference.metadata.citation}`

      title += `\n${details}`
    }
  }

  // WEB TITLE
  else if (typeof reference.metadata?.hostname === 'string') {
    title += reference.metadata.hostname
  }

  // CONTRACT RETRIEVAL TITLE
  else if (typeof reference.metadata?.title === 'string') {
    title += reference.metadata.title
  }

  // DOCUMENT SUMMARIZER TITLE: Construct the title from storage_path
  else if (typeof reference.metadata?.storage_path === 'string') {
    // extract the file name from the full path
    const fileName = reference.metadata.storage_path.split('/').pop()
    title += fileName ?? ''
  }

  // all other cases
  else {
    title = 'Source'
  }

  // Trim beginning and ending whitespace
  title = title.trim()

  return title
}
