import { useNavigate, useParams } from 'react-router-dom'
import { ConversationItem, useGetUsersConversationListQuery } from '../chat-v2-api'
import { ChatV2Feature } from '../store/chat-v2.slice'
import { useAppDispatch, useAppSelector } from '@/store/store-hooks'
import { useEffect, useState } from 'react'
import { CircularProgressContinuousSized } from '@/components/loaders/CircularProgressContinuous'
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query/react'
import { getAuth } from 'firebase/auth'
import { AuthDialogType, openAuthDialog } from '@/store/slices/ui-state.slice'
import { k429RateLimitError, kAnonymousUserLimitMessage429 } from '@/constants/constants-strings'
import { setLastViewedConvoIdByFeature } from '../store/chat-v2-ux.slice'
import ConversationDeleteConfirmDialog from './dialogs/conversation-delete-confirm-dialog'
import deleteConversationById from '../fetch/detch-delete-conversation'
import { RootState } from '@/store/store'
import ChatListSidebarChatItemMenu from './chat-list-sidebar-chat-item-menu'
import { newChatButtonLabel } from './chat-window-utils'
import RetitleConversation from './chat-retitle-conversation'
import { useAnalytics } from '@/analytics/hooks/useAnalytics'
import { AnalyticsEvent } from '@/analytics/schema/events.schema'

const fadeOutActiveChat = {
  content: '""',
  width: '60px',
  background: 'linear-gradient(to right, transparent, #E0E9F1 calc(100% - 20px), #E0E9F1)',
}
const fadeOutInactiveChat = {
  content: '""',
  // background: 'red',
  width: '60px',
  background: 'linear-gradient(to right, transparent, rgb(243, 244, 246) calc(100% - 20px), rgb(243, 244, 246))',
}

type ChatListDateRange = {
  title: string
  conversations: ConversationItem[]
}

type ChatListSidebarProps = {
  onClickCallback?: () => void
}

export default function ChatListSidebar(props: ChatListSidebarProps) {
  const { trackEvent } = useAnalytics()
  const { onClickCallback } = props

  const { chatFeature, chatId } = useParams()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  // Local state
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false)
  const [conversationItemToDelete, setConversationItemToDelete] = useState<ConversationItem | null>(null)
  const [errorMessage, setErrorMessage] = useState<string>('Error loading chat history')

  // Validate the chat feature
  const validFeature: boolean = Object.values(ChatV2Feature).includes(chatFeature as ChatV2Feature)

  // Watch the chat list for new conversations
  const conversationState = useAppSelector((state: RootState) => state.chatV2State)
  const newConversationId = conversationState.newConversationIdUXTrigger
  const conversations = conversationState.conversations

  const pendingConversations = Object.values(conversations).filter((conversation) => conversation.isPending)

  // Load chat list sidebar data (RTK-query)
  const {
    data: dataChatList,
    isLoading: isLoadingChatList,
    refetch: refetchChatList,
    isError: isErrorChatList,
    error: errorChatList,
  } = useGetUsersConversationListQuery(null)

  const error = isErrorChatList || !dataChatList || !validFeature

  // Deletion functions
  // Open the confirm delete dialog
  const openConfirmDeleteDialog = (conversationItem: ConversationItem) => {
    setConversationItemToDelete(conversationItem)
    setConfirmDeleteOpen(true)
  }

  // Refetch list when a new conversation id is detected
  useEffect(() => {
    if (newConversationId === null) return

    // Refetch the conversation list for this feature
    refetchChatList()
  }, [newConversationId, navigate, refetchChatList])

  // Refetch in the background when the chatFeature changes
  useEffect(() => {
    refetchChatList()
  }, [chatFeature, refetchChatList])

  // Trigger rate limit global state dialog for anon users if status is 429
  useEffect(() => {
    // console.log('Running errorChatList useEffect...', errorChatList)
    if (!errorChatList) return

    // Reset error message
    setErrorMessage('Error loading chat history')

    // Check if status is a property of errorChatList
    const hasStatus = Object.prototype.hasOwnProperty.call(errorChatList, 'status')
    if (!hasStatus) return

    const status = (errorChatList as FetchBaseQueryError).status
    if (status == 429) {
      const auth = getAuth()
      const isAnon = auth.currentUser?.isAnonymous

      // Show rate limit exceeded
      setErrorMessage(k429RateLimitError)

      // Anonymous users, load dialog
      if (isAnon) {
        dispatch(
          openAuthDialog({
            authDialogType: AuthDialogType.SIGN_UP,
            tooltipMessage: kAnonymousUserLimitMessage429,
          })
        )
      }
    }
  }, [dispatch, errorChatList])

  const handleChatClick = (id: string) => {
    // Set as the last viewed chat for this feature
    dispatch(setLastViewedConvoIdByFeature({ feature: chatFeature as ChatV2Feature, conversationId: id }))

    // Navigate to the chat
    navigate(`/dashboard/chat/${chatFeature}/${id}`)
  }

  // Show loading indicator (if no data yet) (but not ifFetching (data may exist already))
  if (isLoadingChatList)
    return (
      <div className="p-2 text-sm flex gap-x-2 items-center">
        <CircularProgressContinuousSized size={12} thickness={7} />
        Loading history
      </div>
    )

  // Show error
  if (error || !dataChatList) return <div className="p-2 text-red-700 text-sm">{errorMessage}</div>

  // Match For Feature Changes
  // Rename any lrr features in the dataChatList to lrr_v2 so we can show them both in the same list
  function featureMatchAcrossVersions(conversationFeature: string, chatFeature: string | undefined): boolean {
    // allow lrr to match lrr_v2
    if (conversationFeature == ChatV2Feature.lrr && chatFeature == ChatV2Feature.lrr_v2) return true

    // normal matching for all others
    return conversationFeature == chatFeature
  }

  // Filter for only this feature's conversations
  const featureConversations = dataChatList.conversations.filter((conversation) => featureMatchAcrossVersions(conversation.feature, chatFeature))
  const featurePendingConversations = pendingConversations.filter((conversation) => featureMatchAcrossVersions(conversation.feature, chatFeature))

  // Sort the conversations by modified date
  featureConversations.sort((a, b) => {
    const aDate = new Date(a.modified_on).getTime()
    const bDate = new Date(b.modified_on).getTime()
    return bDate - aDate
  })

  // Construct dates for the time intervals
  const now = new Date()
  const startOfToday = new Date(now.getFullYear(), now.getMonth(), now.getDate())
  const startOfYesterday = new Date(startOfToday)
  startOfYesterday.setDate(startOfYesterday.getDate() - 1)
  const sevenDaysAgo = new Date(startOfToday)
  sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7)

  // Construct empty lists for each time interval
  const todaysConversations: ConversationItem[] = []
  const yesterdaysConversations: ConversationItem[] = []
  const last7DaysConversations: ConversationItem[] = []

  // Collect the the date ranges into an iterable
  const featureConversationsDateSegmented: ChatListDateRange[] = [
    { title: 'Today', conversations: todaysConversations },
    { title: 'Yesterday', conversations: yesterdaysConversations },
    { title: 'Last 7 days', conversations: last7DaysConversations },
  ]

  // Iterate
  // 1. Add conversations for today
  // 2. Add conversations for yesterday
  // 3. Add conversations for last 7 days
  // 4. Add older conversations by month
  for (const conversation of featureConversations) {
    const conversationDate = new Date(conversation.modified_on)

    // Today's Conversations
    if (conversationDate >= startOfToday) {
      todaysConversations.push(conversation)
      continue
    }

    // Yesterday's Conversations
    else if (conversationDate >= startOfYesterday && conversationDate < startOfToday) {
      yesterdaysConversations.push(conversation)
      continue
    }

    // Last 7 Days Conversations
    else if (conversationDate >= sevenDaysAgo && conversationDate < startOfYesterday) {
      last7DaysConversations.push(conversation)
      continue
    }

    // Older - Group by month
    // Identify the month and year of the conversation
    // If there is no ChatListDateRange for the month, add one
    else {
      // Get the month and year (2 digit) of the conversation
      const month = conversationDate.toLocaleString('default', { month: 'short' })
      const year = conversationDate.getFullYear().toString()
      const title = `${month}, ${year}`

      // Check if the title already exists in the array
      const existingTitle = featureConversationsDateSegmented.find((range) => range.title == title)
      if (existingTitle) {
        existingTitle.conversations.push(conversation)
      } else {
        featureConversationsDateSegmented.push({ title: title, conversations: [conversation] })
      }
      continue
    }
  }

  // Create the array of list elements with buffers between each date range
  const featureConversationsJsxElements: JSX.Element[] = []
  featureConversationsDateSegmented.forEach((range) => {
    // Push the title of the range if there are conversations in it
    if (range.conversations.length > 0) {
      featureConversationsJsxElements.push(
        <li key={range.title} className="block pl-2 py-1 text-xs text-black font-bold">
          {range.title}
        </li>
      )
    }

    range.conversations.forEach((conversation) => {
      const active = conversation.id == chatId
      const lastInDateRangeGroup = range.conversations.indexOf(conversation) == range.conversations.length - 1

      featureConversationsJsxElements.push(
        <li
          key={conversation.id}
          className={`group relative block whitespace-nowrap pl-2 py-1 text-sm text-gray-800 cursor-pointer rounded-md ${lastInDateRangeGroup ? 'mb-4' : ''} ${
            active ? 'bg-sky-600 bg-opacity-10' : ''
          }`}
          onClick={() => {
            handleChatClick(conversation.id)

            // Callback
            if (onClickCallback) onClickCallback()
          }}
        >
          <span>{conversation.title}</span>
          <span className="absolute right-0 top-0 h-full rounded-e-md" style={active ? fadeOutActiveChat : fadeOutInactiveChat} />

          <ChatListSidebarChatItemMenu
            chatItemActive={active}
            onSelectDelete={() => openConfirmDeleteDialog(conversation)}
            conversation={conversation}
            chatV2State={conversationState}
          />
        </li>
      )
    })
  })

  return (
    <>
      <RetitleConversation refetchChatList={refetchChatList} />
      <ConversationDeleteConfirmDialog
        title={'Delete Conversation?'}
        message={`You are about to permanently delete "${conversationItemToDelete?.title}".\nPlease confirm.`}
        onConfirm={async () => {
          if (!conversationItemToDelete) {
            console.error('Error deleting conversation: conversationItemToDelete is null.')
            return
          }

          try {
            await deleteConversationById(conversationItemToDelete.id, () => {
              throw new Error('Error deleting conversation.')
            })

            // Trigger refresh of the list
            await refetchChatList()

            // Update local state
            setConfirmDeleteOpen(false)
            setConversationItemToDelete(null)
          } catch (e) {
            console.error('Error in conversation delete flow.', e)
          }

          // Remove the chatId from the URL if we are on it
          if (chatId == conversationItemToDelete.id) {
            navigate(`/dashboard/chat/${chatFeature}`)
          }
        }}
        closeDialog={() => setConfirmDeleteOpen(false)}
        visible={confirmDeleteOpen}
      />
      <div className="overflow-hidden mt-1 ml-2 mr-2">
        {featurePendingConversations.length == 0 && dataChatList.conversations.length == 0 && <div className="text-gray-500 text-sm">No chat history</div>}
        {featurePendingConversations.length > 0 && (
          <ul className="list-none m-0 p-0 mb-4">
            {featurePendingConversations
              .map((conversation) => {
                const active = conversation.id == chatId
                return (
                  <li
                    key={conversation.id}
                    className={`relative block whitespace-nowrap pl-2 py-1 text-sm text-gray-800 cursor-pointer rounded-md ${
                      active ? 'bg-sky-600 bg-opacity-10' : ''
                    }`}
                    onClick={() => {
                      trackEvent(AnalyticsEvent.OpenExistingConversation)
                      handleChatClick(conversation.id)

                      // Callback
                      if (onClickCallback) onClickCallback()
                    }}
                  >
                    <span>{chatFeature == undefined ? 'New chat' : newChatButtonLabel(chatFeature as ChatV2Feature)}</span>
                    <span className="absolute right-0 top-0 w-8 h-full rounded-e-md" style={active ? fadeOutActiveChat : fadeOutInactiveChat} />
                  </li>
                )
              })
              .reverse()}
          </ul>
        )}
        {featureConversations.length > 0 && <ul className="list-none m-0 p-0">{featureConversationsJsxElements}</ul>}
      </div>
    </>
  )
}
