import { ChatV2Feature } from '@/chat-common/store/chat-v2.slice'
import ReferenceViewContentCaseLaw from './reference-view-caselaw'
import ReferenceViewContentLrr from './reference-view-lrr'
import ReferenceViewContentWeb from './reference-view-web'
import ReferenceViewContentCaseLawV2 from './reference-view-caselaw-v2'
import ReferenceViewContentContract from './reference-view-contract'
import ReferenceViewContentLrrV2 from './reference-view-lrr-v2'

// Construct the appropriate reference view based on the feature
import { Dispatch, SetStateAction } from 'react'
import ReferenceViewGcsMarkdown from './gcs-markdown/reference-view-gcs-markdown'
import ReferenceViewPdf from './reference-view-react-pdf-viewer'
import { selectConversationFeature, selectVisibleReference, selectVisibleReferenceUsesPDFRenderer } from '@/chat-common/store/chat-v2.selectors'
import { RootState } from '@/store/store'
import { useAppSelector } from '@/store/store-hooks'

export default function ReferenceViewerConstructor(props: {
  conversationId: string
  setReferenceHtmlCallback?: Dispatch<SetStateAction<string>>
}): JSX.Element | null {
  const { conversationId, setReferenceHtmlCallback } = props

  // Redux state
  const reference = useAppSelector((state: RootState) => selectVisibleReference(state, { chatId: conversationId }))
  const feature = useAppSelector((state: RootState) => selectConversationFeature(state, { chatId: conversationId }))
  const referenceUsesPdfRenderer = useAppSelector((state: RootState) => selectVisibleReferenceUsesPDFRenderer(state, { chatId: conversationId }))

  if (!reference) return <div className={'p-5'}>No reference selected</div>

  switch (feature) {
    case ChatV2Feature.caselaw:
      // Check the metadata version to determine which reference rendering component to use
      if (reference.metadata.version === 2) {
        return <ReferenceViewContentCaseLawV2 conversationId={conversationId} setReferenceHtmlCallback={setReferenceHtmlCallback} />
      } else {
        return <ReferenceViewContentCaseLaw conversationId={conversationId} setReferenceHtmlCallback={setReferenceHtmlCallback} />
      }
    case ChatV2Feature.lrr:
      return <ReferenceViewContentLrr conversationId={conversationId} setReferenceHtmlCallback={setReferenceHtmlCallback} />

    case ChatV2Feature.lrr_v2:
      return <ReferenceViewContentLrrV2 conversationId={conversationId} setReferenceHtmlCallback={setReferenceHtmlCallback} />

    case ChatV2Feature.research: {
      // Check the reference_type to determine which reference viewer to use (caselaw or lrr_v2)
      if (reference.metadata.reference_type == ChatV2Feature.caselaw) {
        return <ReferenceViewContentCaseLawV2 conversationId={conversationId} setReferenceHtmlCallback={setReferenceHtmlCallback} />
      } else {
        return <ReferenceViewContentLrrV2 conversationId={conversationId} setReferenceHtmlCallback={setReferenceHtmlCallback} />
      }
    }

    case ChatV2Feature.documentquery:
    case ChatV2Feature.documentSummarization:
    case ChatV2Feature.assistant: {
      if (referenceUsesPdfRenderer) {
        return <ReferenceViewPdf conversationId={conversationId} />
      } else {
        return <ReferenceViewGcsMarkdown conversationId={conversationId} />
      }
    }

    case ChatV2Feature.websearch:
      return <ReferenceViewContentWeb conversationId={conversationId} />

    case ChatV2Feature.contractsearch:
      return <ReferenceViewContentContract conversationId={conversationId} />

    default:
      return null
  }
}
