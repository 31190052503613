import { RootState } from '@/store/store'
import { createSelector } from '@reduxjs/toolkit'
import { ChatV2Conversation, ChatV2Feature, ChatV2MessageType } from './chat-v2.slice'

/**
 * SELECTOR TEMPLATE
 * A selector is a function that takes the Redux state as an argument and returns a value.
 * createSelector is a function that creates a memoized selector to prevent unnecessary re-renders.
 *
 * This selector retrieves a specific message from a conversation based on 'chatId' and 'messageId'.
 */
// const exampleSelector = createSelector(
//   // Input selector 1 - dive into the relevant part of state to watch (specific conversation)
//   (state: RootState, props: { chatId: string }) => state.chatV2State.conversations[props.chatId],

//   // Input selector 2 - additional props needed for computation (supplied by watching component)
//   (_: RootState, props: { messageId: string }) => props,

//   // Output selector/result function - return the result of the computation
//   (conversation, props) => {
//     const { messageId } = props

//     // Do some computation
//     return conversation?.messages[messageId] ?? null
//   },

//   // Optional options object
//   {
//     memoizeOptions: {
//       // Optional Custom equality check
//       // Only recompute the selector if the previous and next values are different
//       // Can be used to compare specific parts of the state object and not
//       // recompute if other parts change
//       equalityCheck: (prevConversation, nextConversation) => {
//         // Example, only recomputes if the input text is different
//         return prevConversation.currentTextInput === nextConversation.currentTextInput
//       },
//     },
//   }
// )

/**
 * CHILD SELECTOR TEMPLATE
 * A child selector is a selector that depends on another selector.
 * This only recomputes when the parent selector changes, not the entire state.
 *
 * This selector checks if a given search string is present in a specific message's text.
 */
// const exampleChildSelector = createSelector(
//   // Input selector 1 - parent selector
//   exampleSelector,

//   // Input selector 2 - additional props needed for computation (supplied by watching component)
//   (_: RootState, props: { search: string }) => props,

//   // Output selector/result function - return the result of the computation
//   (exampleSelectorResult, props) => {
//     return exampleSelectorResult?.text.includes(props.search) ?? false
//   }
// )

/**
 * Get Conversation
 * An input function that retrieves a conversation with a given 'chatId' from the 'conversations' object.
 * Returns null if the conversation does not exist.
 * Selectors can use a function like this as the first input for convenience.
 */
export const getConversationFromId = (state: RootState, props: { chatId: string | undefined }): ChatV2Conversation | null => {
  return state.chatV2State.conversations[props.chatId ?? ''] ?? null
}

/**
 * Selector: Conversation Exists
 * A child selector that checks if a conversation with a given 'chatId' exists based on
 * the selectConversationFromId parent selector.
 *
 * Reduces re-renders: The Input 1 selector is the output of getConversationFromId rather than the root state.
 * This means that the selector will only recompute when the conversation with the given 'chatId' changes,
 * and not the rest of the state.
 */
export const selectConversationExists = createSelector(
  // Input 1: The output of the 'selectorInputConversationFromId' selector.
  getConversationFromId,

  // Input 2: No additional props needed for this computation
  // Do not need to define here

  // Output selector/result function:
  // Returns 'true' if a conversation exists, 'false' otherwise.
  // The double negation (!!) ensures a boolean result.
  (converstaion) => !!converstaion
)

/**
 * Selector: Visible Reference Exists
 * A child selector that checks if a visible reference exists in a conversation.
 */
export const selectConversationVisibleReferenceExists = createSelector(getConversationFromId, (conversation) => !!conversation?.visibleReference)

/**
 * Selector: Visible Reference
 * A selector that retrieves the visible reference from a conversation.
 */
export const selectVisibleReference = createSelector(
  (state: RootState, props: { chatId: string }) => state.chatV2State.conversations[props.chatId]?.visibleReference ?? null,
  (visibleReference) => visibleReference
)

/**
 * Selector: Visible Reference Needs PDF Renderer
 * A selector that retrieves whether the visible reference needs the PDF renderer
 */
export const selectVisibleReferenceUsesPDFRenderer = createSelector(
  (state: RootState, props: { chatId: string | undefined }) => state.chatV2State.conversations[props.chatId ?? '']?.visibleReference ?? null,
  (visibleReference) => visibleReference?.metadata?.process_version === 'V2'
)

/**
 * Selector Visible Reference Render Trigger
 * A selector that retrieves the render trigger for the visible reference from a conversation.
 */
export const selectVisibleReferenceRenderTrigger = createSelector(
  (state: RootState, props: { chatId: string }) => state.chatV2State.conversations[props.chatId]?.visibleReferenceRenderTrigger ?? '',
  (renderTrigger) => renderTrigger
)

/**
 * Selector: Conversation Is Loading
 */
export const selectConversationIsLoading = createSelector(
  // Input selector: Extract 'isLoading' directly from the state
  (state: RootState, props: { chatId: string | undefined }) => state.chatV2State.conversations[props.chatId ?? '']?.isLoading ?? false,
  // Result function: Simply return 'isLoading'
  (isLoading) => isLoading
)
/**
 * Selector: Conversation Feature
 */
export const selectConversationFeature = createSelector(
  // Input selector: Extract 'feature' directly from the state
  (state: RootState, props: { chatId: string | undefined }) => state.chatV2State.conversations[props.chatId ?? '']?.feature ?? null,
  // Result function: Return 'feature'
  (feature) => feature
)

/**
 * Select: isDiscontinuedFeature
 */
export const selectIsDiscontinuedFeature = createSelector(
  // Input selector: Extract 'feature' directly from the state
  (state: RootState, props: { chatId: string | undefined }) => state.chatV2State.conversations[props.chatId ?? '']?.feature ?? null,
  // Result function: Check if the feature is discontinued
  (feature) => {
    return feature === ChatV2Feature.lrr
  }
)

/**
 * Selector: Conversation Messages
 */
export const selectConversationMessages = createSelector(
  // Input selector: Extract 'messages' directly from the state
  (state: RootState, props: { chatId: string | undefined }) => state.chatV2State.conversations[props.chatId ?? '']?.messages ?? {},
  // Result function: Return 'messages'
  (messages) => messages
)

/**
 * Selector: Conversation current source
 */
export const selectConversationCurrentSource = createSelector(
  // Input selector: Extract 'currentSource' directly from the state
  (state: RootState, props: { chatId: string | undefined }) => state.chatV2State.conversations[props.chatId ?? '']?.currentSource ?? null,
  // Result function: Return 'currentSource'
  (currentSource) => currentSource
)

/**
 * Selector: Conversation Title
 */
export const selectConversationTitle = createSelector(
  // Input selector: Extract 'title' directly from the state
  (state: RootState, props: { chatId: string | undefined }) => state.chatV2State.conversations[props.chatId ?? '']?.title ?? null,
  // Result function: Return 'title'
  (title) => title
)

/**
 * Selector for conversation message ids
 * - Reduces re-renders since it's inputs won't change as frequently as if we were watching the root state object
 *
 * - Custom input equality check to compare the previous and next message keys
 * - Inputs will not be considered different if the keys are the same, even if the message objects are different
 * - Default behavior would recompute the selector (and re-render) every time the 'messages' objects change
 */

/**
 * Message Keys Equality Check
 * Custom equality check to compare the previous and next message keys
 * and ignore other changes in the objects
 * @param prevMessages
 * @param nextMessages
 * @returns
 */
const equalityCheckMessageKeys = (prevMessages: Record<string, any>, nextMessages: Record<string, any>) => {
  const prevKeys = prevMessages ? Object.keys(prevMessages) : []
  const nextKeys = nextMessages ? Object.keys(nextMessages) : []
  if (prevKeys.length !== nextKeys.length) return false

  // Return true if every key at each index is the same
  return prevKeys.every((key, index) => key === nextKeys[index])
}
export const selectConversationMessageIds = createSelector(
  // INPUT 1
  selectConversationMessages,

  (messages) => Object.keys(messages),
  {
    memoizeOptions: {
      // Custom equality check evaluates INPUT 1 to determine whether it has changed
      equalityCheck: equalityCheckMessageKeys,
    },
  }
)

/**
 * Selector to get a message by id
 */
export const selectMessageFromId = createSelector(
  (state: RootState, props: { chatId: string; messageId: string }) => {
    const conversation = state.chatV2State.conversations[props.chatId]
    return conversation?.messages[props.messageId] ?? null
  },
  (message) => message
)

/**
 * Child selector: message type
 */
export const selectMessageType = createSelector(
  (state: RootState, props: { chatId: string; messageId: string }) => {
    const conversation = state.chatV2State.conversations[props.chatId]
    return conversation?.messages[props.messageId]?.metadata?.message_type ?? null
  },
  (messageType) => messageType
)

/**
 * Selector to see if the messageId is the most recent message
 */
export const selectIsMostRecentMessage = createSelector(
  selectConversationMessages,
  (_: RootState, props: { messageId: string }) => props,
  (messages, props) => {
    // Get most recent message
    const mostRecentMessage = Object.values(messages).reverse()[0] ?? null

    // The provided messageId matches the most recent message
    return mostRecentMessage?.metadata?.message_id === props.messageId
  }
)

/**
 * Selector for whether submission in this conversation is blocked
 * Returns true if the conversation is loading, the form has an error, or the current source is null
 *
 * Does not check for an empty text input.
 */
export const selectConversationSubmitBlocked = createSelector(getConversationFromId, (conversation) => {
  if (!conversation) return true

  // Infer values
  const feature = conversation.feature as ChatV2Feature
  const isLoading = conversation.isLoading
  const messages = conversation.messages ?? {}
  const currentSource = conversation.currentSource ?? null
  const formValidationError = conversation.formValidationError ?? false

  // Global settings for all features
  if (isLoading || formValidationError || !currentSource) return true

  // Features That Require Selected Files
  if (feature == ChatV2Feature.documentquery) {
    // If selected files length == 0
    if (currentSource.selected_files.length == 0) return true
  }
  if (feature == ChatV2Feature.contractanalysis) {
    // If file path is empty
    if (currentSource.file_path == '') return true
  }

  if (feature == ChatV2Feature.documentSummarization) {
    // If selected file is empty
    if (currentSource.selected_file === '') return true
  }

  if (feature == ChatV2Feature.lrr_v2) {
    // If file path is empty
    const lrrSelections = currentSource.lrr_selections ?? []
    if (lrrSelections.length == 0) return true
  }

  if (feature == ChatV2Feature.research) {
    // If neither federal or state sources are selected
    if (currentSource.federal_court_ids == null && currentSource.state_court_ids == null) return true
  }

  // Features that only allow 1 message
  if (feature == ChatV2Feature.contractanalysis) {
    // If there are already messages
    if (Object.keys(messages).length > 0) return true
  }

  return false
})

/**
 * Selector: The current text input in the conversation
 */
export const selectConversationCurrentTextInput = createSelector(
  (state: RootState, props: { chatId: string }) => state.chatV2State.conversations[props.chatId]?.currentTextInput ?? '',
  (currentTextInput) => currentTextInput
)

/**
 * Selector for whether to show the loading bubble
 * If
 * - The conversation is loading
 * - The most recent message is a user_query
 * - The text is empty
 */
export const selectShowMessageAsLoadingBubble = createSelector(
  getConversationFromId,
  (_: RootState, props: { messageId: string }) => props,
  (conversation, props) => {
    if (!conversation) return false
    const messages = conversation.messages
    if (!messages) return false

    // Get most recent message
    const mostRecentMessage = Object.values(messages).reverse()[0] ?? null

    // The provided messageId matches the most recent message
    const isMostRecentMessage = mostRecentMessage?.metadata?.message_id === props.messageId

    // Is the most recent message a user_query?
    const mostRecentMessageIsUserQuery = mostRecentMessage?.metadata?.message_type === ChatV2MessageType.user_query

    return conversation.isLoading && isMostRecentMessage && mostRecentMessageIsUserQuery
  }
)
