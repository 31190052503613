import * as Dialog from '@radix-ui/react-dialog'
import { Cross2Icon } from '@radix-ui/react-icons'
import { FileUploadStatusList } from './FileUploadStatusList'
import { useEffect, useRef } from 'react'
import { UploadTaskStatus } from './FilesDropZone'

interface FileUploadStatusDialogProps {
  open: boolean
  tasks: Map<string, UploadTaskStatus>
  onClose: () => void
}

const FileUploadStatusDialog = (props: FileUploadStatusDialogProps) => {
  const { open, tasks, onClose } = props

  // Scroll to the bottom of the list when tasks update
  const scrollRef = useRef<HTMLDivElement>(null)
  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollTo({ top: scrollRef.current.scrollHeight, behavior: 'smooth' })
    }
  }, [tasks])

  const completedTasks = Array.from(tasks.values()).filter((task) => task.complete).length

  return (
    <Dialog.Root open={open} onOpenChange={onClose}>
      <Dialog.Portal>
        <Dialog.Content className="fixed top-[50%] left-[50%] pb-4 lg:left-[calc(50%+248px)] max-h-[85vh] w-[90vw] max-w-[450px] translate-x-[-50%] translate-y-[-50%] rounded-[6px] bg-white p-[25px] shadow-[0_10px_38px_-10px_rgba(0,0,0,0.35),_0_10px_20px_-15px_rgba(0,0,0,0.2)] focus:outline-none">
          <div className="flex justify-between items-center mb-2">
            <Dialog.Title className="m-0 text-[17px] font-medium text-gray-800">
              {completedTasks} {completedTasks === 1 ? 'upload' : 'uploads'} complete
            </Dialog.Title>
            <Dialog.Close asChild autoFocus>
              <button className="inline-flex items-center justify-center rounded-full text-gray-700 hover:text-gray-900" onClick={onClose} aria-label="Close">
                <Cross2Icon className="w-5 h-5" />
              </button>
            </Dialog.Close>
          </div>
          <hr className="border-gray-200 mt-4" />
          <div className="text-[15px] leading-normal">
            <FileUploadStatusList tasks={tasks} scrollRef={scrollRef} />
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  )
}

export { FileUploadStatusDialog }
