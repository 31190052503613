import { ReactNode } from 'react'

/**
 * AppInit Component
 * - Component to handle application initialization tasks
 *
 * BLOCKING:
 * - Can be used to block the application until initialization is complete
 * - Conditionally render children based on initialization status
 *
 * NON-BLOCKING:
 * - Can be used to execute startup functions in the background that are not-blocking
 */
export const AppInit = ({ children }: { children: ReactNode }) => {
  // Example blocking initialization
  // if(condition == true) {
  //   return <FullPageLoader />
  // }

  return children
}
