import { selectMessageFromId } from '@/chat-common/store/chat-v2.selectors'
import { ChatV2Feature } from '@/chat-common/store/chat-v2.slice'
import BlinkingCursor from '@/components/loaders/BlinkingCursor'
import { RootState } from '@/store/store'
import { useAppSelector } from '@/store/store-hooks'
import { getBrandName } from '@/util/enterprise'
import { useEffect, useState } from 'react'

enum ChatLoadingStatusMessage {
  findingSources = 'Finding relevant sources...',
  analyzingQuery = 'Analyzing query...',
  analyzingSources = 'Analyzing sources...',
  generatingResponse = 'Generating response...',
  draftingResponse = 'Drafting response...',
  reviewingContract = 'Reviewing contract...',
  compilingSummary = 'Compiling summary and suggestions...',
  generatingSummary = 'Generating summary...',
}

type ChatLoadingBubbleProps = {
  chatId: string
  messageId: string
}

export default function ChatLoadingBubble(props: ChatLoadingBubbleProps) {
  const { chatId, messageId } = props

  // Redux State Selectors
  const message = useAppSelector((state: RootState) =>
    selectMessageFromId(state, {
      messageId,
      chatId,
    })
  )
  const messageFeature = message.metadata.feature as ChatV2Feature

  // Loading message state
  const [loadingMessage, setLoadingMessage] = useState<string>('Generating response...')

  // Messages cycle
  let timeoutId1: NodeJS.Timeout | null = null
  let timeoutId2: NodeJS.Timeout | null = null

  const draftingTimeoutLength = 2000
  const contractAnalysisLength = 7500
  const standardTimeoutLength = 15000

  function triggerLoadingMessagesCycle() {
    // console.log('Triggering loading messages cycle')
    switch (messageFeature) {
      case ChatV2Feature.drafting:
        setLoadingMessage(ChatLoadingStatusMessage.analyzingQuery)
        timeoutId1 = setTimeout(() => {
          setLoadingMessage(ChatLoadingStatusMessage.draftingResponse)
        }, draftingTimeoutLength)
        break
      case ChatV2Feature.contractanalysis:
        setLoadingMessage(ChatLoadingStatusMessage.reviewingContract)
        timeoutId1 = setTimeout(() => {
          setLoadingMessage(ChatLoadingStatusMessage.compilingSummary)
        }, contractAnalysisLength)
        break
      case ChatV2Feature.documentSummarization:
        setLoadingMessage(ChatLoadingStatusMessage.analyzingQuery)
        timeoutId1 = setTimeout(() => {
          setLoadingMessage(ChatLoadingStatusMessage.generatingSummary)
        }, standardTimeoutLength)
        break
      default:
        setLoadingMessage(ChatLoadingStatusMessage.findingSources)
        timeoutId1 = setTimeout(() => {
          setLoadingMessage(ChatLoadingStatusMessage.analyzingSources)
          timeoutId2 = setTimeout(() => {
            setLoadingMessage(ChatLoadingStatusMessage.generatingResponse)
          }, standardTimeoutLength)
        }, standardTimeoutLength)
        break
    }
  }

  function cancelLoadingMessagesCycle() {
    if (timeoutId1) clearTimeout(timeoutId1)
    if (timeoutId2) clearTimeout(timeoutId2)

    // Reset timeout IDs to null
    timeoutId1 = null
    timeoutId2 = null

    // Optionally, reset the loading message
    setLoadingMessage('')
  }

  // Start the loading message cycle if the messageId changes
  useEffect(() => {
    triggerLoadingMessagesCycle()

    return () => {
      cancelLoadingMessagesCycle()
    }
  }, [messageId])

  return (
    <>
      <div className={`p-2 rounded-lg text-base whitespace-pre-wrap bg-white`}>
        <div className={`font-bold text-base mb-1`}>
          <div>{getBrandName()}</div>
        </div>
        <div className={`text-sm leading-6 mb-1`}>
          <div className={'inline-block'}>{loadingMessage}</div> <BlinkingCursor blink={true} />
        </div>
      </div>
      <div className={`h-2`} />
    </>
  )
}
