import { ChatV2Feature } from '@/chat-common/store/chat-v2.slice'
import { RootState } from '@/store/store'
import { useAppSelector } from '@/store/store-hooks'
import ChatControls from './chat-controls'
import { selectConversationFeature, selectConversationMessageIds } from '@/chat-common/store/chat-v2.selectors'
import ChatFormInput from './chat-form-input'

type ChatFormProps = {
  conversationId: string
}

/**
 * The Chat Form component.
 *
 * Contains the chat form source controls, and the text area input.
 * Has additional logic for single message features, anonymous users.
 * @returns
 */
export default function ChatForm(props: ChatFormProps) {
  const { conversationId } = props

  // Conversation values
  const feature = useAppSelector((state: RootState) => selectConversationFeature(state, { chatId: conversationId }))
  const messageIds = useAppSelector((state: RootState) => selectConversationMessageIds(state, { chatId: conversationId }))

  // Inferred Values
  const messagesLength = messageIds.length

  // SINGLE MESSAGE FEATURES: Do not show the form after a single message.
  const isSingleMessageFeature: boolean = feature == ChatV2Feature.contractanalysis || feature == ChatV2Feature.documentSummarization
  if (isSingleMessageFeature && messagesLength > 1) {
    return (
      <div className={'text-sm text-center py-2'}>
        This feature currently supports 1 message per {feature == ChatV2Feature.contractanalysis ? 'analysis' : 'chat'}. Create a new{' '}
        {feature == ChatV2Feature.contractanalysis ? 'analysis' : 'chat'} to make changes.
      </div>
    )
  }

  // Default: Show the form
  return (
    <div>
      {/* Chat Feature Controls */}
      <div className={'px-1 mb-2 flex flex-col items-start'}>
        <ChatControls conversationId={conversationId} />
      </div>

      {/* Chat Input Forms */}
      <ChatFormInput conversationId={conversationId} />
    </div>
  )
}
