import { FileUploadContext } from '@/context/file-upload-context'
import { AttachFileOutlined, Upload, FolderOpen } from '@mui/icons-material'
import * as Popover from '@radix-ui/react-popover'
import { useContext, useState } from 'react'

type FileActionMenuProps = {
  disabled?: boolean
}

enum FileAction {
  Upload = 'upload',
  Select = 'select',
}

const fileActions = [
  {
    value: FileAction.Upload,
    label: 'Upload Files',
    description: 'Select documents from your computer',
    icon: <Upload />,
  },
  {
    value: FileAction.Select,
    label: 'Add from File Manager',
    description: "Select documents you've already uploaded",
    icon: <FolderOpen />,
  },
]

export default function FileActionMenu(props: FileActionMenuProps) {
  const { disabled } = props
  const [open, setPopoverOpen] = useState<boolean>(false)
  const { openSystemFileSelector } = useContext(FileUploadContext)

  const handleSelection = (action: string) => {
    setPopoverOpen(false)
    if (action === FileAction.Upload) {
      openSystemFileSelector()
    } else if (action === FileAction.Select) {
      // TODO: Open the custom drive file selector
      console.warn('File manager selection not implemented yet')
    }
  }

  return (
    <div className="flex">
      <Popover.Root open={open}>
        <Popover.Trigger asChild>
          <button
            onClick={() => setPopoverOpen(!open)}
            className={`${
              disabled ? 'opacity-50 cursor-default' : 'opacity-none hover:text-sky-600 cursor-pointer'
            } text-gray-700 w-[35px] h-[35px] inline-flex items-center justify-center`}
            aria-label="Add files by uploading or selecting from file manager"
            title="Add Files"
            disabled={disabled}
            aria-disabled={disabled}
          >
            <AttachFileOutlined sx={{ transform: 'rotate(45deg)' }} />
          </button>
        </Popover.Trigger>
        <Popover.Portal>
          <Popover.Content
            onEscapeKeyDown={() => setPopoverOpen(false)}
            onInteractOutside={() => setPopoverOpen(false)}
            className="PopoverContent z-50 min-w-[260px] mr-5 px-2 py-2 bg-white rounded-md shadow-[0px_10px_38px_-10px_rgba(22,_23,_24,_0.75),_0px_10px_20px_-15px_rgba(22,_23,_24,_0.5)]"
          >
            <div className="flex flex-col gap-1">
              <h2 className="text-[14px] mt-1 text-center font-semibold text-gray-600">Add Files</h2>
              {fileActions.map((action) => (
                <button
                  key={action.value}
                  className="flex p-2 items-center text-left rounded-md hover:bg-sky-50 text-gray-700"
                  onClick={() => handleSelection(action.value as FileAction)}
                  aria-label={action.label}
                >
                  {action.icon}
                  <div className="ml-3 text-[12px]">
                    <label className="font-semibold w-[75px]" htmlFor="action">
                      {action.label}
                    </label>
                    <p>{action.description}</p>
                  </div>
                </button>
              ))}
            </div>
          </Popover.Content>
        </Popover.Portal>
      </Popover.Root>
    </div>
  )
}
