import { useParams } from 'react-router'
import { ChatV2Feature } from '../store/chat-v2.slice'
import { useMediaQuery } from '@mui/material'
import { assistantModes } from '@/chat-common/components/form-source-controls/assistant/assistant-modes'

export default function NewChatIntroAssistant() {
  const { chatFeature } = useParams()

  // Handle invalid params
  if (!chatFeature || chatFeature !== ChatV2Feature.assistant) {
    console.error(`Invalid featureId ${chatFeature}.`)
    return null
  }

  // Icon size variables
  const mediumIconSize = '1.5rem' // Material UI default medium size
  const betweenMediumAndLarge = '1.75rem' // Custom size between medium and large

  const isXsScreen = useMediaQuery('(min-width: 576px)')

  const modes = assistantModes.map((mode) => ({
    ...mode,
    icon: <mode.icon style={{ fontSize: isXsScreen ? betweenMediumAndLarge : mediumIconSize }} />,
  }))

  return (
    <div className="flex flex-grow items-end">
      <div className="p-4 w-full max-w-3xl mx-auto">
        <div className="pl-1 mb-4 xs:mb-5">
          <h2 className="text-lg font-bold mb-2 xs:mb-3 text-left">Hi, how may I be of assistance?</h2>
          <p className="text-sm text-gray-600 ">
            Paxton is your intelligent AI assistant. Enter a prompt or upload documents in intelligent mode, and Paxton will determine the best path forward.
            Learn more about modes:
          </p>
        </div>
        <div className="flex flex-col space-y-2 xs:space-y-4">
          {modes.map((mode) => (
            <div key={mode.title} className="border p-3 sm:p-4 rounded-lg w-full flex items-center">
              <div className="mr-4">{mode.icon}</div>
              <div className="flex-grow">
                <div className="flex justify-between items-center">
                  <div className="font-bold text-xs xs:text-sm">{mode.title}</div>
                  {mode.recommended && (
                    <span className="text-2xs leading-none ml-auto px-[6px] py-[2.5px] mb-1 xs:mb-0 rounded-full bg-purple-500 text-white flex items-center justify-center">
                      Recommended
                    </span>
                  )}
                </div>
                <p className="text-xs xs:text-sm mt-1 xs:mt-0 text-gray-500">{mode.description}.</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
