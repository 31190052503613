import React from 'react'
import ReactDOM from 'react-dom/client'
import './css/index.css'
import { Provider } from 'react-redux'
import { store } from './store/store.ts'
import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom'
import Root from './routes/root.tsx'
import Dashboard from './routes/dashboard/dashboard-layout.tsx'
import SignInPage from './routes/sign-in/sign-in-page.tsx'
import SignUpPage from './routes/sign-up/sign-up-page.tsx'
import PasswordResetPage from './routes/password/password-reset-page.tsx'
import { AuthContextProvider } from './context/auth-context.tsx'
import CallbackPageSubsription from './routes/callback/subscription/callback-page-subscription.tsx'
import AccountPageGuard from './components/auth/AccountPageGuard.tsx'
import AccountActionPage from '@/components/auth/AccountAction.tsx'
import SubscriptionPendingContainer from '@/components/stripe/SubscriptionPendingContainer.tsx'

import * as Sentry from '@sentry/react'
import { captureConsoleIntegration } from '@sentry/integrations'

import FilesPage from './routes/dashboard/files/FilesPage.tsx'
import ExamplesPage from './routes/dashboard/examples/ExamplesPage.tsx'
import { HubspotConversationsProvider } from './context/hubspot-conversations-provider.tsx'
import AuditPage from './routes/dashboard/audit/AuditPage.tsx'
import BooleanBuilderPage from './routes/dashboard/boolean-builder/BooleanBuilderPage.tsx'
import { kGetFeatureFlagStatus, kFeatureFlags } from './constants/constants-feature-flags.ts'
import AnonymousGuard from './components/guards/anonymous-guard.tsx'
import GuidesPage from './routes/dashboard/guides/GuidesPage.tsx'
import ErrorPage from './components/error/error-page.tsx'
import ChatV2Page from './routes/dashboard/chat-v2/ChatV2Page.tsx'
import DashboardHome from './routes/dashboard/dashboard-home.tsx'
import ChatV2AnonFeatureGuard from './components/guards/chat-v2-anon-feature-guard.tsx'
import SubscriptionGuard from './components/guards/subscription-guard.tsx'
import { nanoid } from 'nanoid'
import { getAuth } from 'firebase/auth'
import PasswordCreatePage from './routes/password/password-create-page.tsx'
import DocumentEditingPage from './document-editing/views/DocumentEditingPage.tsx'
import NewDocumentSessionPage from './document-editing/views/new-document-session/NewDocumentSessionPage.tsx'
import SurveyGuard from './components/guards/survey-guard.tsx'
import AdminDashboardPage from './organizations/views/AdminDashboardPage.tsx'
import OrgAdminGuard from './components/guards/org-admin-guard.tsx'
import InvitePage from './routes/invite/InvitePage.tsx'
import DocumentEditingSessionsList from './document-editing/views/DocumentEditingSessionsList.tsx'
import { Userpilot } from 'userpilot'
import StandaloneReferenceViewerCaseLawPage from './standalone-reference-viewer/caselaw/views/StandaloneReferenceViewerCaseLawPage.tsx'
import { isPaxtonBrand } from './util/enterprise.ts'
import { AppInit } from './app-init/app-init.tsx'

// Global window property declarations (type defs)
declare global {
  interface Window {
    // Hubspot stuff
    hsConversationsSettings: Record<string, any>
    hsConversationsOnReady: Array<() => void>
    HubSpotConversations: {
      on: any
      off: any
      widget: {
        status: () => { loaded: boolean; pending: boolean }
        load: (params?: { widgetOpen: boolean }) => void
        remove: () => void
        open: () => void
        close: () => void
        refresh: (openToNewThread?: boolean) => void
      }
    }
  }
}

const isPaxton = isPaxtonBrand()

// Add sentry error logging for production and staging environments
if (import.meta.env.MODE === 'production' || import.meta.env.MODE === 'staging') {
  // console.log(`Initializing sentry with environment: ${import.meta.env.MODE} and version ${APP_VERSION}`)
  Sentry.init({
    dsn: 'https://82f4e2d22d504875b81bd29ebd8a2e30@o4505370434928640.ingest.sentry.io/4505432129994752',
    environment: import.meta.env.MODE,
    release: APP_VERSION,
    integrations: [
      new Sentry.BrowserTracing(),
      new Sentry.Replay({
        maskAllText: false,
        maskAllInputs: false,
      }),
      captureConsoleIntegration({
        levels: ['error'],
      }),
    ],

    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    // staging, prod, local
    tracePropagationTargets: ['https://paxton-app-api-mla66wcquq-uc.a.run.app', 'https://api2.paxton.ai', 'http://localhost'],

    // Performance Monitoring
    tracesSampleRate: 0.3, // Capture 30% of the transactions

    // Session Replay
    replaysSessionSampleRate: 0.5, // This sets the sample rate at 50%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.

    // Ignore errors containing these strings / expressions
    // These are strings inside the error message content (reported even without the stack trace)
    ignoreErrors: [
      'analytics.js', // segment analytics errors
      'Amplitude Logger [Error]',
      'Error sending segment performance metrics',

      // Match any error message starting with the specified phrase:
      /^Error sending Amplitude event/i,
      /^Error sending segment performance metrics/i,

      // Add patterns for analytics platform error strings
      /POST.*api2\.amplitude\.com/i,
      /POST.*api\.segment\.io/i,
    ],

    // Error deny list - don't report errors from these urls
    // These are the urls that are reported in the error stack trace
    denyUrls: [
      // Amplitude errors
      /api2\.amplitude\.com/i,

      // segment analytics errors
      /cdn\.segment\.com/i,
      /api\.segment\.io/i,

      // userpilot
      /js\.userpilot\.io/i,

      // Chrome extensions
      /extensions\//i,
      /^chrome:\/\//i,
      /^chrome-extension:\/\//i,
    ],

    beforeSend(event) {
      // Modify the event for enterprise users, just in case but email shouldn't exist for enterprise users
      if (event.user?.email && !isPaxtonBrand(event.user?.brand)) {
        // Don't send user's email address
        delete event.user.email
      }
      return event
    },
  })

  if (isPaxton) {
    // Initialize Userpilot for non-enterprise sites
    Userpilot.initialize('NX-efe1461d')
  }
}

// The Router
const router = createBrowserRouter([
  {
    path: '/',
    element: <Root />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: 'sign-in',
        element: isPaxton ? <SignInPage /> : <Navigate to="/dashboard" />,
      },
      {
        path: 'sign-up',
        element: isPaxton ? <SignUpPage /> : <Navigate to="/dashboard" />,
      },
      {
        path: 'forgot-password',
        element: isPaxton ? <PasswordResetPage /> : <Navigate to="/dashboard" />,
      },
      {
        path: 'create-password',
        element: isPaxton ? <PasswordCreatePage /> : <Navigate to="/dashboard" />,
      },
      {
        path: 'invite/:inviteId?',
        element: <InvitePage />,
      },
      {
        path: 'account-action',
        element: <AccountActionPage />,
      },
      {
        path: 'pending-subscription',
        element: <SubscriptionPendingContainer />,
      },
      {
        path: 'dashboard',
        element: (
          <SubscriptionGuard>
            <SurveyGuard>
              <Dashboard />
            </SurveyGuard>
          </SubscriptionGuard>
        ),
        children: [
          // Websocket chat is behind a feature flag - REMOVED - LEFT HERE FOR EXAMPLE
          ...(kGetFeatureFlagStatus(kFeatureFlags.WEBSOCKET_CHAT) ? [] : []),
          {
            path: '',
            element: <DashboardHome />,
          },
          {
            path: 'admin',
            element: (
              <AnonymousGuard>
                <OrgAdminGuard>
                  <AdminDashboardPage />
                </OrgAdminGuard>
              </AnonymousGuard>
            ),
          },
          {
            path: 'account',
            element: <AccountPageGuard />,
          },
          {
            path: 'chat/:chatFeature/:chatId?',
            element: (
              <ChatV2AnonFeatureGuard showAuthOnGuard={true}>
                <ChatV2Page />
              </ChatV2AnonFeatureGuard>
            ),
          },
          {
            path: 'document-editing/new',
            element: (
              <ChatV2AnonFeatureGuard showAuthOnGuard={true}>
                <NewDocumentSessionPage />
              </ChatV2AnonFeatureGuard>
            ),
          },
          {
            path: 'document-editing/list',
            element: (
              <ChatV2AnonFeatureGuard showAuthOnGuard={true}>
                <DocumentEditingSessionsList />
              </ChatV2AnonFeatureGuard>
            ),
          },
          {
            path: 'document-editing/doc/:editingSessionId',
            element: (
              <ChatV2AnonFeatureGuard showAuthOnGuard={true}>
                <DocumentEditingPage />
              </ChatV2AnonFeatureGuard>
            ),
          },
          {
            path: 'reference-viewer/caselaw/:parent_id',
            element: (
              <ChatV2AnonFeatureGuard showAuthOnGuard={true}>
                <StandaloneReferenceViewerCaseLawPage />
              </ChatV2AnonFeatureGuard>
            ),
          },
          {
            path: 'audit',
            element: (
              <AnonymousGuard>
                <AuditPage />
              </AnonymousGuard>
            ),
          },
          {
            path: 'boolean-composer',
            element: (
              <AnonymousGuard>
                <BooleanBuilderPage />
              </AnonymousGuard>
            ),
          },
          {
            path: 'drives/:driveName/*',
            element: (
              <AnonymousGuard>
                <FilesPage />
              </AnonymousGuard>
            ),
          },
          {
            path: 'examples',
            element: <ExamplesPage />,
          },
          {
            path: 'guides',
            element: <GuidesPage />,
          },
        ],
      },

      {
        path: 'callback/subscription/success',
        element: <CallbackPageSubsription />,
      },
    ],
  },

  // Redirects
  {
    path: '/dashboard/chat',
    element: <Navigate to="/dashboard" replace />,
  },
  {
    path: '/dashboard/laws-regulations',
    element: <Navigate to="/dashboard/chat/lrr_v2" replace />,
  },
  {
    path: '/dashboard/case-law',
    element: <Navigate to="/dashboard/chat/caselaw" replace />,
  },
  {
    path: '/dashboard/chat/sec',
    element: <Navigate to="/dashboard" replace />,
  },
  {
    path: '/dashboard/chat/contractanalysis/*',
    element: <Navigate to="/dashboard/document-editing/list" replace />,
  },
  {
    path: '/dashboard/web-search',
    element: <Navigate to="/dashboard/chat/websearch" replace />,
  },
  {
    path: '/dashboard/files',
    element: <Navigate to="/dashboard/drives/my-drive" replace />,
  },
  {
    path: '/dashboard/drives',
    element: <Navigate to="/dashboard/drives/my-drive" replace />,
  },
  {
    path: '/dashboard/chat/lrr',
    element: <Navigate to="/dashboard/chat/lrr_v2" replace />,
  },
  {
    path: '/dashboard/document-editing',
    element: <Navigate to="/dashboard/document-editing/list" replace />,
  },
])

// HTMX Setup
// confirm allows us to pause the request and perform async operations
// beforeSend allows us to add headers and modify the every htmx request
function setUpHtmx() {
  // HTMX VARS - for use in HTMX event listeners
  // HTMX auth token we set before each request
  let htmxAuthToken: string | null = null

  // htmx:confirm - async-compatible event listener prior to every htmx request
  // Purpose: set the htmxAuthToken value to the Firebase idToken
  document.body.addEventListener('htmx:confirm', async (e: Event) => {
    const htmxEvent = e as CustomEvent

    // Halt the request
    htmxEvent.preventDefault()

    // Get the idToken from Firebase
    const currentUser = getAuth().currentUser
    const idToken = (await currentUser?.getIdToken()) ?? null
    if (idToken == null) {
      console.error(`No idToken found for htmx request, currentUser: ${currentUser}`)
    }
    htmxAuthToken = idToken

    // Continue the request
    htmxEvent.detail.issueRequest()
  })

  // htmx:beforeRequest - alter the request headers
  document.body.addEventListener('htmx:beforeRequest', async function (e: Event) {
    const htmxEvent = e as CustomEvent // Cast to CustomEvent for TypeScript
    const xhr = htmxEvent.detail.xhr as XMLHttpRequest // Detail property contains the xhr object

    // ADD SENTRY TRANSACTION ID
    const uniqueId = nanoid()
    xhr.setRequestHeader('X-Transaction-Id', uniqueId)

    // Add the auth token to the request
    xhr.setRequestHeader('Authorization', `Bearer ${htmxAuthToken}`)
  })

  // htmx:afterRequest - handle response error reporting
  document.body.addEventListener('htmx:afterRequest', async function (e: Event) {
    const htmxEvent = e as CustomEvent // Cast to CustomEvent for TypeScript
    const xhr = htmxEvent.detail.xhr as XMLHttpRequest // Detail property contains the xhr object

    // If failure
    if (htmxEvent.detail.failed) {
      console.error(`htmx request failed: ${xhr.status} ${xhr.statusText} ${xhr.response}`)

      // Get the transaction id
      const sentry_transaction_id = xhr.getResponseHeader('X-Transaction-Id')

      // Log to sentry
      Sentry.withScope((scope) => {
        scope.setTags({ transaction_id: sentry_transaction_id })

        Sentry.captureException(new Error(`htmx request failed: ${xhr.status}`), {
          extra: {
            statusText: xhr.statusText,
            statusResponse: xhr.response,
            requestConfig: htmxEvent.detail.requestConfig,
            target: htmxEvent.detail.target,
          },
        })
      })
    }
  })
}
setUpHtmx()

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <Provider store={store}>
      <AppInit>
        <AuthContextProvider>
          <HubspotConversationsProvider>
            <RouterProvider router={router} />
          </HubspotConversationsProvider>
        </AuthContextProvider>
      </AppInit>
    </Provider>
  </React.StrictMode>
)
