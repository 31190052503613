import { useEffect, useRef, useState } from 'react'
import NewChatIntro from './new-chat-intro'
import { useAppSelector } from '@/store/store-hooks'
import { ChatV2Message, ChatV2Feature } from '../store/chat-v2.slice'
import { ChevronDownIcon } from '@radix-ui/react-icons'
import { CircularProgressContinuousSized } from '@/components/loaders/CircularProgressContinuous'
import { RootState } from '@/store/store'
import MessageFeedbackDialog from './dialogs/message-feedback-dialog'
import ChatWindowDropZone from './chat-window-dropzone'
import ErrorComponent, { ErrorComponentProps } from '@/components/error/error-component'
import loadMissingConversationIntoState from '../fetch/fetch-missing-conversation'
import { selectConversationExists, selectConversationMessageIds, selectIsDiscontinuedFeature } from '../store/chat-v2.selectors'
import BubbleConstructor from './message-bubbles/bubble-constructor'
import ChatForm from './form-source-controls/chat-form'
import ChatWindowDropZoneMultifile, { ChatWindowDropZoneMultifileRef } from './chat-window-dropzone-multifile'
import { FileUploadContext } from '@/context/file-upload-context'
import NewChatIntroAssistant from './new-chat-intro-assistant'

type ChatWindowProps = {
  chatId: string
  chatFeature: ChatV2Feature
}

/**
 * Chat Window
 *
 * This component is mainly for templating the Chat Window area.
 * - Scrollable messages area
 * - Chat form at the bottom
 * @param props
 * @returns
 */
export default function ChatWindow(props: ChatWindowProps) {
  const { chatId, chatFeature } = props

  // Redux State Selectors
  const conversationExists = useAppSelector((state: RootState) => selectConversationExists(state, { chatId }))
  const messageIds = useAppSelector((state: RootState) => selectConversationMessageIds(state, { chatId }))

  // Conversation Loading state
  const [conversationLoadError, setConversationLoadError] = useState<ErrorComponentProps | null>(null)
  const [conversationLoading, setConversationLoading] = useState<boolean>(false)

  // MessageFeedbackDialog state
  const [messageFeedbackDialogVisible, setMessageFeedbackDialogVisible] = useState<boolean>(false)
  const [messageFeedbackDialogMessage, setMessageFeedbackDialogMessage] = useState<ChatV2Message | null>(null)
  const [messageFeedbackDialogPositive, setMessageFeedbackDialogPositive] = useState<boolean | null>(null)

  // MessageFeedbackDialog open
  function openMessageFeedbackDialog(message: ChatV2Message, positive: boolean) {
    setMessageFeedbackDialogMessage(message)
    setMessageFeedbackDialogPositive(positive)
    setMessageFeedbackDialogVisible(true)
  }

  // Refs
  const scrollingChatWindowRef = useRef<HTMLDivElement>(null)
  const chatWindowDropZoneMultifileRef = useRef<ChatWindowDropZoneMultifileRef | null>(null)

  const openSystemFileSelector = () => {
    chatWindowDropZoneMultifileRef?.current?.openSystemFileSelector()
  }

  // First scroll to bottom at chat load
  const [stickToBottom, setStickToBottom] = useState<boolean>(true)

  // Whether this is a discontinued feature
  const isDiscontinuedFeature = useAppSelector((state: RootState) => selectIsDiscontinuedFeature(state, { chatId }))

  const showMultifileDropZone = chatFeature === ChatV2Feature.assistant

  // Scroll to the bottom of the chat window
  function scrollToBottom() {
    const container = scrollingChatWindowRef.current
    if (!container) return

    container.scrollTop = container.scrollHeight
  }

  /**
   * Load chat messages
   * NOTE: loadMissingConversationIntoState will skip fetching if it's already in state
   */
  useEffect(() => {
    // Reset the error
    setConversationLoadError(null)

    // Load the conversation messages if we don't have them in state
    loadMissingConversationIntoState(
      chatId,
      chatFeature,
      (errorProps: ErrorComponentProps) => {
        setConversationLoadError(errorProps)
      },
      (isLoading: boolean) => {
        setConversationLoading(isLoading)
      }
    )
  }, [chatId])

  useEffect(() => {
    const container = scrollingChatWindowRef.current
    if (!container) return

    const handleScroll = () => {
      const scrollHeight = container.scrollHeight
      const scrollTop = container.scrollTop
      const clientHeight = container.clientHeight

      const bufferHeight = 2
      const scrollPosition = scrollHeight - scrollTop - clientHeight
      const atBottom = scrollPosition < bufferHeight

      // console.log(`Scroll height: ${scrollHeight}, scroll top: ${scrollTop}, client height: ${clientHeight}`)
      // console.log(`Scrolling position: ${scrollPosition}, at bottom? ${atBottom}`)
      if (atBottom) {
        setStickToBottom(true)
      } else {
        setStickToBottom(false)
      }
    }

    container.addEventListener('scroll', handleScroll)
    return () => container?.removeEventListener('scroll', handleScroll)
  }, [])

  /**
   * Stick To Bottom
   * Conditionally Scroll To The Bottom of Chat Window
   *
   * If not at the bottom, clicking the arrow indicator will scroll
   */
  useEffect(() => {
    const container = scrollingChatWindowRef.current
    if (!container) return

    if (stickToBottom) {
      scrollToBottom()
    }
  }, [messageIds]) // Call every time messageIds changes

  // If there was an error loading the conversation, show the error component
  if (conversationLoadError) {
    return (
      <div className={'p-5'}>
        <ErrorComponent {...conversationLoadError} />
      </div>
    )
  }

  return (
    <>
      {messageFeedbackDialogMessage && messageFeedbackDialogPositive != null && (
        <MessageFeedbackDialog
          message={messageFeedbackDialogMessage}
          positive={messageFeedbackDialogPositive}
          visible={messageFeedbackDialogVisible}
          onClose={() => {
            setMessageFeedbackDialogVisible(false)
            setMessageFeedbackDialogMessage(null)
            setMessageFeedbackDialogPositive(null)
          }}
        />
      )}

      <div className="flex flex-grow flex-col items-center">
        <div ref={scrollingChatWindowRef} className="flex-1 flex-grow overflow-y-scroll w-full">
          {/* This div will contain the conversation and will be scrollable */}
          <div className="max-w-3xl mx-auto h-full">
            {/* Render circular progress indicator if we're loading the messages */}
            {conversationLoading && (
              <div className={'w-full h-full flex justify-center items-center'}>
                <CircularProgressContinuousSized size={30} thickness={7} hexColor={'#0285c7'} />
              </div>
            )}

            {/* Render the new chat intro if we are on the new chat screen and have not sent an initial message */}
            {!conversationLoading && (!conversationExists || messageIds.length == 0) && (
              <div className={'min-h-full flex flex-col pb-5'}>
                {chatFeature === ChatV2Feature.assistant ? <NewChatIntroAssistant /> : <NewChatIntro />}
                <ChatWindowDropZone
                  onUpdate={() => {
                    scrollToBottom()
                  }}
                />
                {/* Multifile version of the chat window dropzone */}
                {showMultifileDropZone && <ChatWindowDropZoneMultifile ref={chatWindowDropZoneMultifileRef} />}
              </div>
            )}

            {conversationExists && (
              <>
                {/* Render ChatWindowDropZoneMultifile with the UI hidden once the conversation has started */}
                {showMultifileDropZone && <ChatWindowDropZoneMultifile ref={chatWindowDropZoneMultifileRef} hideDropZoneUI={true} />}
                {/* Render the conversation messages */}
                {messageIds.map((id) => {
                  return (
                    <BubbleConstructor
                      key={`${id}`}
                      chatId={chatId}
                      messageId={id}
                      openFeedbackDialog={openMessageFeedbackDialog}
                      onNewMessageText={() => {
                        if (stickToBottom) {
                          scrollToBottom()
                        }
                      }}
                    />
                  )
                })}
              </>
            )}
          </div>
        </div>

        {/* This form will stay at the bottom of the screen */}
        {isDiscontinuedFeature && (
          <div className={'pt-4 pb-12 font-bold text-center'}>
            We've updated Paxton's AI engine and this chat cannot be continued.
            <br />
            Please start a new chat.
          </div>
        )}
        {conversationExists && !isDiscontinuedFeature && (
          <>
            <div className={'w-full pb-4 pt-2 max-w-3xl m-auto px-5 border-t'}>
              {/* Floating go to bottom button */}
              <div
                className={`w-full flex justify-center items-center cursor-pointer ${stickToBottom ? 'hidden' : 'block'}`}
                onClick={() => {
                  scrollToBottom()
                }}
              >
                <div
                  className={
                    'absolute -mt-16 w-8 h-8 grid place-items-center rounded-full bg-sky-600 bg-opacity-50 hover:bg-opacity-80 transition-all duration-500 ease-in-out text-white text-opacity-90'
                  }
                >
                  <ChevronDownIcon width="24" height="24" />
                </div>
              </div>

              {/* Chat Form */}
              <FileUploadContext.Provider value={{ openSystemFileSelector: openSystemFileSelector }}>
                <ChatForm conversationId={chatId} />
              </FileUploadContext.Provider>
            </div>
          </>
        )}
      </div>
    </>
  )
}
