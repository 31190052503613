import { store } from '@/store/store'
import {
  ChatV2FullResponseSchema,
  ChatV2Message,
  ChatV2MessageChunk,
  ChatV2MessageMetadataSchema,
  chatV2AddConversationFromMetadata,
  chatV2AddMessageFromMetadata,
  chatV2HandleConfidencePayload,
  chatV2HandleFullResponse,
  chatV2HandleMessageChunk,
  chatV2InsertUserQueryMessage,
} from './store/chat-v2.slice'
import { z } from 'zod'
import { migratePendingViewToServerView } from './store/chat-v2-ux.slice'
import { ConfidenceWSEventDataSchema } from '@/components/confidence-indicator/schema/confidence-indicator-schema'

// General schema and type for all websocket events
export const ChatV2Event = z.object({
  event: z.string(),
  data: z.any(),
})
export type ChatV2EventType = z.infer<typeof ChatV2Event>

/**
 * ChatV2 Dispatcher
 * Accepts stringified JSON data and validates it against known chat schemas
 * in order to dispatch against the correct reducers.
 *
 * If a fromPendingId is provided, we delete it from state after the first event is received from the server.
 *
 * @param pendingConversationId string id of the pending conversation we may be migrating away from
 * @param onMigrationCallback callback function to run after migration from pending to server conversation is complete
 * @param onMessageIdCallback callback function to run after receiving a message id from the websocket
 * @param eventData stringified json data returned from websocket event
 * @param dispatch Redux Toolkit App Dispatch from the store
 */
export default function chatV2Dispatcher(
  pendingConversationId: string | null,
  onMigrationCallback: (newConversationId: string) => void,
  onMessageIdCallback: (newMessageId: string) => void,
  userMessage: ChatV2Message,
  eventData: string
) {
  // Parse the data
  const jsonData = JSON.parse(eventData)

  // Validate the data against the general event schema
  const validatedData = ChatV2Event.safeParse(jsonData)
  if (!validatedData.success) {
    throw new Error(`Invalid event schema for chatV2Dispatcher: ${eventData}`)
  }

  // Destructure the data
  const { event, data } = validatedData.data
  // console.log('Handling event: ', event, ' with data: ', data)

  // Switch - Evaluate the type of payload from its schema and dispatch appropriately
  switch (true) {
    case event == 'status':
      console.log(`Status received: `, data)
      break

    // Specific order of operations for creating conversation, inserting our query message, the inserting the server response message
    // For a streaming response, we first receive a "metadata" package we can use to create the initial empty message + metadata
    case event == 'metadata': {
      console.log('Handling metadata: ', data)
      const metadata = ChatV2MessageMetadataSchema.safeParse(data)
      if (!metadata.success) throw new Error(`Invalid metadata schema for chatV2Dispatcher: ${metadata.error}`)

      /** THIS FLOW IS POTENTIALLY THE FIRST REPSONSE FROM SERVER WITH CONVERSATION DATA */

      // Create conversation from metadata if it does not exist
      store.dispatch(chatV2AddConversationFromMetadata({ metadata: metadata.data, fromPendingId: pendingConversationId }))

      // Actions to take if we are moving from a pending conversation
      if (pendingConversationId) {
        // Update the calling function to set the new conversation as active for future websocket events
        onMigrationCallback(metadata.data.conversation_id)

        // Migrate last-viewed conversation to the new conversation
        store.dispatch(
          migratePendingViewToServerView({
            feature: metadata.data.feature,
            pendingConversationId: pendingConversationId,
            conversationId: metadata.data.conversation_id,
          })
        )
      }

      // Insert the user's initial query into the conversation (action handles if it already exists)
      store.dispatch(chatV2InsertUserQueryMessage({ conversationId: metadata.data.conversation_id, message: userMessage }))

      // Dispatch the metadata
      store.dispatch(chatV2AddMessageFromMetadata(metadata.data))

      // Call the callback with the new message id
      onMessageIdCallback(metadata.data.message_id)

      break
    }

    // Response: full instant server response message
    case event == 'response': {
      console.log('Received full response: ', validatedData.data)
      const response = ChatV2FullResponseSchema.safeParse(data)
      if (!response.success) throw new Error(`Invalid full response schema for chatV2Dispatcher: ${response.error}`)

      /** THIS FLOW IS POTENTIALLY THE FIRST REPSONSE FROM SERVER WITH CONVERSATION DATA */

      // Create conversation from metadata if it does not exist
      store.dispatch(chatV2AddConversationFromMetadata({ metadata: response.data.metadata, fromPendingId: pendingConversationId }))

      // Actions to take if we are moving from a pending conversation
      if (pendingConversationId) {
        // Update the calling function to set the new conversation as active for future websocket events
        onMigrationCallback(response.data.metadata.conversation_id)

        // Migrate last-viewed conversation to the new conversation
        store.dispatch(
          migratePendingViewToServerView({
            feature: response.data.metadata.feature,
            pendingConversationId: pendingConversationId,
            conversationId: response.data.metadata.conversation_id,
          })
        )
      }

      // Insert the user's initial query into the conversation (action handles if it already exists)
      store.dispatch(chatV2InsertUserQueryMessage({ conversationId: response.data.metadata.conversation_id, message: userMessage }))

      // Insert full response into state
      store.dispatch(chatV2HandleFullResponse(response.data))

      // Call the callback with the new message id
      onMessageIdCallback(response.data.metadata.message_id)

      break
    }

    case event == 'request_params':
      console.log('Received request params (this does nothing yet): ', validatedData.data)
      break

    // Chunk: streaming message response
    case event == 'chunk': {
      const chunk = ChatV2MessageChunk.safeParse(data)
      if (!chunk.success) throw new Error(`Invalid chunk schema for chatV2Dispatcher: ${chunk}`)

      // Concatenate the message chunk to the message
      store.dispatch(chatV2HandleMessageChunk(chunk.data))

      // Call the callback with the new message id
      onMessageIdCallback(chunk.data.message_id)
      break
    }

    // TODO: FE component for confidence data
    case event == 'confidence': {
      // console.log('Received confidence data: ', data)
      const confidence = ConfidenceWSEventDataSchema.safeParse(data)
      if (!confidence.success) throw new Error(`Invalid full response schema for chatV2Dispatcher: ${confidence.error}`)

      // Add the confidence data to the state
      store.dispatch(chatV2HandleConfidencePayload(confidence.data))

      break
    }

    default:
      console.error(`Unknown event type for chatV2Dispatcher: ${eventData}`)
      break
  }
}
