import { BanknotesIcon, CpuChipIcon, DocumentMagnifyingGlassIcon } from '@heroicons/react/24/outline'
import { brandedAIFriendlyName } from '@/util/enterprise'

const examplesList = [
  {
    source: 'State Courts',
    title: 'Find precedents',
    description: 'I need to establish whether my client has a "dangerous dog" after a dog bite injury.',
    icon: DocumentMagnifyingGlassIcon,
    iconForeground: 'text-teal-700',
    iconBackground: 'bg-teal-50',
  },
  {
    source: 'Federal Courts',
    title: 'Find and summarize critical case elements',
    description: 'Find cases where "price-fixing" was successfully proven and summarize the elements that were crucial to the prosecution\'s case.',
    icon: BanknotesIcon,
    iconForeground: 'text-purple-700',
    iconBackground: 'bg-purple-50',
  },
  {
    source: 'State Courts',
    title: 'Research how concepts are defined',
    description: 'Cases that have dealt with "unconscionable" contract terms. How have courts defined this concept.',
    icon: CpuChipIcon,
    iconForeground: 'text-sky-700',
    iconBackground: 'bg-sky-50',
  },
  {
    source: 'Federal Courts',
    title: 'Find how a statute was applied',
    description: 'Cases where the Computer Fraud and Abuse Act was applied. Pay attention to how unauthorized access was defined.',
    icon: CpuChipIcon,
    iconForeground: 'text-yellow-700',
    iconBackground: 'bg-yellow-50',
  },
  // {
  //   source: 'United States Code',
  //   title: 'Federal taxation queries',
  //   description: 'What are the requirements to deduct home expenses for business use?',
  //   icon: ReceiptPercentIcon,
  //   iconForeground: 'text-sky-700',
  //   iconBackground: 'bg-sky-50',
  // },
  // {
  //   source: 'United States Code',
  //   title: 'Environmental regulations policy and procedure',
  //   description: 'Draft a policy and procedures for storing and disposing of solvents used in an auto repair shop',
  //   icon: GlobeAmericasIcon,
  //   iconForeground: 'text-yellow-700',
  //   iconBackground: 'bg-yellow-50',
  // },
  // {
  //   source: 'New York Codes Rules and Regulations',
  //   title: 'State specific health regulations',
  //   description: 'What are the licensing and operating standards for hospitals?',
  //   icon: HeartIcon,
  //   iconForeground: 'text-rose-700',
  //   iconBackground: 'bg-rose-50',
  // },
  // {
  //   source: 'New York Codes Rules and Regulations',
  //   title: 'State specific insurance regulations',
  //   description: 'What are the rules for the licensing and conduct of insurance brokers and agents in New York?',
  //   icon: PaperClipIcon,
  //   iconForeground: 'text-sky-700',
  //   iconBackground: 'bg-sky-50',
  // },
]

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}

export default function ExamplesBlockCaseLaw() {
  return (
    <>
      <div className="bg-white shadow rounded-lg sm:max-w-screen-lg">
        <div className="mb-5 px-4 py-5 sm:p-6">
          <h1 className={'text-base font-semibold leading-6 text-gray-900'}>How to query case law</h1>
          <p className={'my-2 text-sm text-gray-500'}>
            {brandedAIFriendlyName} has a wide range of capabilities. Here are examples of tasks that can be delegated. Feel free to experiment.
          </p>
        </div>
      </div>
      <div className="sm:max-w-screen-lg divide-y divide-gray-200 overflow-hidden rounded-lg bg-gray-200 shadow sm:grid sm:grid-cols-2 sm:gap-px sm:divide-y-0">
        {examplesList.map((example, exampleIndex) => (
          <div
            key={example.title}
            className={classNames(
              exampleIndex === 0 ? 'rounded-tl-lg rounded-tr-lg sm:rounded-tr-none' : '',
              exampleIndex === 1 ? 'sm:rounded-tr-lg' : '',
              exampleIndex === examplesList.length - 2 ? 'sm:rounded-bl-lg' : '',
              exampleIndex === examplesList.length - 1 ? 'rounded-bl-lg rounded-br-lg sm:rounded-bl-none' : '',
              'group relative bg-white p-6 focus-within:ring-2 focus-within:ring-inset focus-within:ring-sky-500'
            )}
          >
            <div>
              <span className={classNames(example.iconBackground, example.iconForeground, 'inline-flex rounded-lg p-3 ring-4 ring-white')}>
                <example.icon className="h-6 w-6" />
              </span>
            </div>
            <div className="mt-8">
              <h3 className="text-base font-semibold leading-6 text-gray-900">{example.title}</h3>
              <p className={'mt-2 text-sm text-gray-500'}>
                {example.source}: "{example.description}"
              </p>
            </div>
          </div>
        ))}
      </div>
    </>
  )
}
